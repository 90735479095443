import { Controller } from 'stimulus';
import Swiper, { Navigation } from 'swiper';
Swiper.use([Navigation]);

export default class extends Controller {
  static targets = ['prev', 'next', 'slider'];

  get numberOfSlides() {
    return this.element.querySelectorAll('.js-nowShowingSlider .swiper-slide').length;
  }

  get visibleSlideCount() {
    if (window.innerWidth > 1024) {
      return this.numberOfSlides > 3 ? 3 : this.numberOfSlides;
    } else if (window.innerWidth > 700) {
      return this.numberOfSlides > 2 ? 2 : this.numberOfSlides;
    } else {
      return this.numberOfSlides > 1 ? 1 : this.numberOfSlides;
    }
  }

  connect() {
    const screeningCard = this.element.querySelectorAll('.screening__card');
    const lastScreeningCard = screeningCard[screeningCard.length - 1];
    if (lastScreeningCard) {
      lastScreeningCard.addEventListener(
        'screeningCardProcessed',
        this.handleLastScreeningCardProcessed
      );
    }
  }

  disconnect() {
    const screeningCard = this.element.querySelectorAll('.screening__card');
    const lastScreeningCard = screeningCard[screeningCard.length - 1];
    if (lastScreeningCard) {
      lastScreeningCard.removeEventListener(
        'screeningCardProcessed',
        this.handleLastScreeningCardProcessed
      );
    }
    if (this.swiper) {
      this.swiper.destroy(true, true);
    }
  }

  handleLastScreeningCardProcessed = () => {
    this.initHeroSlider();
  };

  initHeroSlider() {
    if (this.swiper) {
      this.swiper.update();
    } else {
      this.swiper = new Swiper(this.sliderTarget, {
        slidesPerView: 1.1,
        loop: false,
        speed: 400,
        autoHeight: true,
        observeParents: true,
        observer: true,
        spaceBetween: 25,
        navigation: {
          prevEl: this.prevTarget,
          nextEl: this.nextTarget,
        },
        breakpoints: {
          700: {
            spaceBetween: 35,
            slidesPerView: this.numberOfSlides > 2 ? 2.25 : 2,
          },
          1024: {
            slidesPerView: this.numberOfSlides > 3 ? 3.25 : 3,
          },
          1280: {
            spaceBetween: 50,
            slidesPerView: this.numberOfSlides > 3 ? 3.5 : 3,
          },
        },
      });
    }
  }
}
